import React, {useState} from "react"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"
import {
    useAssignCalendarToUsersMutation,
    useDeleteCalendarMutation,
    useGetPublicHolidayCalendarsQuery
} from "../../../api/publicHolidayCalendars"
import DDList from "../../../toolkits/DDList/DDList"
import FlexiblePopup from "../../../toolkits/FlexiblePopup"


const DeleteAssignedCalendarPopup = ({calendar, setUserMessage, close}) => {
    const [deleteCalendar, {isLoading}] = useDeleteCalendarMutation()
    const [currentCalendar, setCurrentCalendar] = useState(null)
    const [assignCalendarToUsers] = useAssignCalendarToUsersMutation()
    const { data: result } = useGetPublicHolidayCalendarsQuery()
    const calendars = result?.items || []

    const handleSubmit = () => {
        const data = {
            user_ids: calendar.users.map(user => user.id),
        }

        assignCalendarToUsers({calendar_id: currentCalendar.id, data})
            .unwrap()
            .then(() => {
                return deleteCalendar(calendar.id).unwrap()
            })
            .then(() => {
                close()
                setUserMessage({ message: "Calendar was successfully deleted!", code: SUCCESS_CODE })
            })
            .catch(error => {
                setUserMessage({ message: error.message, code: ERROR_CODE })
            })
    }

    return (
        <FlexiblePopup>
            <div className="calendars-popup">
                <div className="t-h2 calendars-popup-title">Delete Calendar</div>
                <div className="t-b1 calendars-popup-subtitle">The `{calendar.name}` calendar has assigned employees. Please reassign them to another calendar before deleting this one. </div>
                <DDList
                    text_label="Calendar"
                    placeholder="Reassign to"
                    defaultValue={({value: currentCalendar, label: currentCalendar?.name})}
                    listOptions={calendars?.filter(cal => cal.id !== calendar.id).map((calendar) => ({value: calendar, label: calendar.name}))}
                    onChange={calendar => setCurrentCalendar(calendar.value)}
                />
                <div className="calendars-popup-bottom">
                    <SmallButton disabled={isLoading} btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton loading={isLoading} onClick={handleSubmit}> REASSIGN AND DELETE </SmallButton>
                </div>
            </div>
        </FlexiblePopup>
    )
}

export default DeleteAssignedCalendarPopup
