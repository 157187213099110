import {React, useState, useEffect} from "react"
import KebabIcon from "@mui/icons-material/MoreVert"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CarouselFilter from "../../../toolkits/CarouselFilter"
import CalendarEventItem from "./CalendarEventItem"
import {useDuplicateCalendarEventsMutation} from "../../../api/publicHolidayCalendars"
import {ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"
import StyledCircularProgress from "../../../toolkits/CircularProgress/CircularProgress"
import Menu from "@material-ui/core/Menu"
import {CustomMenuItem} from "../../Profile/components/Other/CustomMenuItem"
import DeleteCalendarPopup from "./DeleteCalendarPopup"
import Popup from "reactjs-popup"
import MarkCalendarAsDefaultPopup from "./MarkCalendarAsDefaultPopup"
import DeleteDefaultCalendarPopup from "./DeleteDefaultCalendarPopup"
import DeleteAssignedCalendarPopup from "./DeleteAssignedCalendarPopup"

const EventsSidePanel = ({calendar, isOpen, onClose, setUserMessage, loading}) => {
    const currentYear = new Date().getFullYear()
    const [yearsOptions, setYearsOptions] = useState([])
    const [selectedYear, setSelectedYear] = useState(currentYear)
    const [duplicateCalendarEvents] = useDuplicateCalendarEventsMutation()
    const [anchorEl, setAnchorEl] = useState(null)
    const [showDeleteCalendarPopup, setShowDeleteCalendarPopup] = useState(false)
    const [showDeleteDefaultCalendarPopup, setShowDeleteDefaultCalendarPopup] = useState(false)
    const [showDeleteAssignedCalendarPopup, setShowDeleteAssignedCalendarPopup] = useState(false)
    const [showMarkCalendarAsDefaultPopup, setShowMarkCalendarAsDefaultPopup] = useState(false)

    useEffect(() => {
        if (calendar?.events?.length > 0) {
            const eventYears = [...new Set(calendar.events.map(event => new Date(event.date).getFullYear()))]
            const yearsToInclude = [...eventYears, currentYear, currentYear + 1]
            const sortedYears = [...new Set(yearsToInclude)].sort((a, b) => a - b)
            setYearsOptions(sortedYears)
        } else {
            setYearsOptions([currentYear, currentYear + 1])
        }
        setSelectedYear(currentYear)
    }, [calendar?.events, loading])

    const handleOverlayClick = (e) => {
        if (e.target.className === "events-side-panel-overlay") {
            onClose()
        }
    }

    const handleCopyPrevious = () => {
        const previousYear = selectedYear - 1
        duplicateCalendarEvents({
            calendar_id: calendar.id,
            params: { source_year: previousYear, target_year: selectedYear },
        })
            .unwrap()
            .then(() => {
                setUserMessage({message: "Holidays were successfully duplicated!", code: SUCCESS_CODE})

            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    if (!isOpen) return null

    return (
        <>
            <div className="events-side-panel-overlay" onClick={handleOverlayClick}></div>
            <div className="events-side-panel">
                <div className="events-side-panel-header">
                    <div className="events-side-panel-header-title">
                        <span><span className={`t-s6 ${calendar.events.length > 0 ? "" : "events-side-panel-header-title-default-label"}`}>{calendar.name}</span> <span className="t-b2 events-side-panel-header-title-default-label">{calendar.is_default ? "(default)" : ""}</span></span>
                        <KebabIcon onClick={(e) => setAnchorEl(e.currentTarget)}/>
                        <Menu
                            id="simple-menu"
                            disableScrollLock={false}
                            anchorEl={anchorEl}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            getContentAnchorEl={null}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            open={!!anchorEl}
                            onClick={() => setAnchorEl(null)}
                            keepMounted
                        >
                            <div>
                                <CustomMenuItem
                                    label="Mark as default"
                                    onClick={() => setShowMarkCalendarAsDefaultPopup(true)}
                                />
                                <CustomMenuItem
                                    label="Delete"
                                    onClick={() => {
                                        if (calendar.is_default) {
                                            setShowDeleteDefaultCalendarPopup(true)
                                        }
                                        if (calendar.users?.length) {
                                            setShowDeleteAssignedCalendarPopup(true)
                                        }
                                        if (!calendar.is_default && !calendar.users?.length) {
                                            setShowDeleteCalendarPopup(true)
                                        }
                                    }}
                                />
                            </div>
                        </Menu>
                    </div>
                    <div className="events-side-panel-header-years">
                        {yearsOptions.length ?
                            <CarouselFilter
                                options={yearsOptions}
                                selectedOption={selectedYear}
                                onOptionChange={setSelectedYear}
                            /> : <></>
                        }
                    </div>
                </div>
                <div className="events-side-panel-content">
                    {loading ? (
                        <StyledCircularProgress />
                    ) : calendar?.events?.filter(event => new Date(event.date).getFullYear() === selectedYear).length === 0 ? (
                        <div className="events-side-panel-content-empty">
                            <div className="t-s1">There are no public holidays yet</div>
                            <div className="t-s4">Add your first holiday or copy from another year</div>
                            <div className="events-side-panel-content-empty-buttons">
                                <SmallButton onClick={handleCopyPrevious} btnType="secondary">
                                    Copy previous
                                </SmallButton>
                                <SmallButton>Add holiday</SmallButton>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="events-side-panel-content-events">
                                {calendar?.events?.length &&
                                    calendar.events
                                        .filter(event => new Date(event.date).getFullYear() === selectedYear)
                                        .map(event => <CalendarEventItem key={event.id} event={event} />)}
                            </div>
                            <div className="events-side-panel-footer">
                                <SmallButton onClick={onClose} btnType="secondary">
                                    Close
                                </SmallButton>
                                <SmallButton>Save</SmallButton>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <Popup open={showDeleteCalendarPopup} closeOnDocumentClick={false} onClose={() => setShowDeleteCalendarPopup(false)} modal>
                {close => (
                    <DeleteCalendarPopup
                        calendar={calendar}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>
            <Popup open={showDeleteDefaultCalendarPopup} closeOnDocumentClick={false} onClose={() => setShowDeleteDefaultCalendarPopup(false)} modal>
                {close => (
                    <DeleteDefaultCalendarPopup
                        calendar={calendar}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>
            <Popup open={!showDeleteDefaultCalendarPopup && showDeleteAssignedCalendarPopup} closeOnDocumentClick={false} onClose={() => setShowDeleteAssignedCalendarPopup(false)} modal>
                {close => (
                    <DeleteAssignedCalendarPopup
                        calendar={calendar}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>
            <Popup open={showMarkCalendarAsDefaultPopup} closeOnDocumentClick={false} onClose={() => setShowMarkCalendarAsDefaultPopup(false)} modal>
                {close => (
                    <MarkCalendarAsDefaultPopup
                        calendar={calendar}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>
        </>
    )
}

export default EventsSidePanel