import React, {useState} from "react"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import FlexiblePopup from "../../../toolkits/FlexiblePopup"

import {ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"
import {
    useDeleteCalendarMutation,
    useGetPublicHolidayCalendarsQuery, useMarkCalendarAsDefaultMutation
} from "../../../api/publicHolidayCalendars"
import DDList from "../../../toolkits/DDList/DDList"


const DeleteDefaultCalendarPopup = ({calendar, setUserMessage, close}) => {
    const [deleteCalendar, {isLoading}] = useDeleteCalendarMutation()
    const { data: result } = useGetPublicHolidayCalendarsQuery()
    const calendars = result?.items || []
    const [markCalendarAsDefault] = useMarkCalendarAsDefaultMutation()
    const [currentCalendar, setCurrentCalendar] = useState(null)

    const handleSubmit = () => {
        markCalendarAsDefault(currentCalendar.id)
            .unwrap()
            .then(() => {
                if (calendar.users.length === 0) {
                    return deleteCalendar(calendar.id).unwrap()
                } else {
                    close()
                    setUserMessage({message: `Default calendar is changed to ${currentCalendar.name}!`, code: SUCCESS_CODE})
                    return Promise.resolve()
                }
            })
            .then(() => {
                if (calendar.users.length === 0) {
                    setUserMessage({message: "Calendar was successfully deleted!", code: SUCCESS_CODE})
                }
                close()
            })
            .catch(error => {
                setUserMessage({message: error.response?.data?.message || error.message, code: ERROR_CODE})
            })
    }


    return (
        <FlexiblePopup text_title="Delete Default Calendar">
            <div className="calendars-popup">
                <div className="t-h2 calendars-popup-title">Delete Default Calendar</div>
                <div className="t-b1 calendars-popup-subtitle">The `{calendar.name}` calendar is set as the default. Please select a new default before deleting the current one.</div>
                <DDList
                    text_label="Calendar"
                    placeholder="Set as default"
                    defaultValue={({value: currentCalendar, label: currentCalendar?.name})}
                    listOptions={calendars?.filter(cal => cal.id !== calendar.id).map((calendar) => ({value: calendar, label: calendar.name}))}
                    onChange={calendar => setCurrentCalendar(calendar.value)}
                />
                <div className="calendars-popup-bottom">
                    <SmallButton disabled={isLoading} btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton loading={isLoading} onClick={handleSubmit}> SET AND DELETE </SmallButton>
                </div>
            </div>
        </FlexiblePopup>
    )
}

export default DeleteDefaultCalendarPopup
